import React from 'react'

import SiteLayout from '../layout/SiteLayout'
import SEO from '../components/SEO'
import Section from '../components/Section'
import { Title, TitleContainer, TitleIntro } from '../components/Title'
import purposeImg from '../images/svg/about-us/our-purpose.svg'
import storyImg from '../images/svg/about-us/our-story.svg'
import diffImg from '../images/svg/about-us/our-difference.svg'
import { Intro, IntroTitle, IntroText } from '../components/Intro'
import {
  SideBySide,
  SideBySideImg,
  SideBySideText,
  SideBySideTitle,
} from '../components/SideBySide'

const AboutUs = () => {
  return (
    <SiteLayout header="light">
      <SEO title="Small business accountants" />
      <TitleContainer>
        <Title>About us</Title>
        <TitleIntro>
          We're changing how small businesses see accountants. We're friendly
          and easy to talk to, we're available when you need us and we're
          affordable.
        </TitleIntro>
      </TitleContainer>
      <Section>
        <Intro className="mb-6">
          <IntroTitle>Qualified, regulated and insured</IntroTitle>
          <IntroText>
            Our practice licence is issued by AAT who regulate us and carry out
            regular quality assurance visits to ensure our work and advice is of
            the highest standard. We're also fully registered as a tax agent
            with HMRC, registered with the ICO for data protection and have
            comprehensive professional indemnity insurance.
          </IntroText>
        </Intro>
        <SideBySide>
          <SideBySideImg src={purposeImg} alt="Our purpose" />
          <SideBySideText>
            <SideBySideTitle>Our purpose</SideBySideTitle>
            <p>
              We take care of accounting for small business owners, but our
              purpose extends far beyond just taking care of the paperwork.
              We’re here to help small business owners understand what they want
              to do and where they want to go. To us, it’s more than just number
              crunching. It’s about giving you more time to focus on what truly
              matters.
            </p>
          </SideBySideText>
        </SideBySide>
        <SideBySide reverse>
          <SideBySideText>
            <SideBySideTitle>Our story</SideBySideTitle>
            <p>
              Since our launch in June 2016 we have delivered a breath-taking
              service to a whole host of small businesses. From builders to
              hairdressers, car dealers to shed builders, we have learned how to
              make a real impact on the lives of our clients. And we’ll never
              stop learning. We are also entirely self-funded with no external
              investment, so every decision we make is to move closer to our
              purpose of providing an accounting service that small businesses
              deserve.
            </p>
          </SideBySideText>
          <SideBySideImg src={storyImg} alt="Our story" />
        </SideBySide>
        <SideBySide>
          <SideBySideImg src={diffImg} alt="Our difference" />
          <SideBySideText>
            <SideBySideTitle>Our difference</SideBySideTitle>
            <p>
              We offer unlimited advice to all clients at no extra cost. We make
              it as easy as taking a photo of a receipt to keep your books up to
              date. We provide you with useful, easy-to-understand reports on
              your business’s performance so you can make better decisions. Our
              fees are agreed upfront and charged monthly to avoid any nasty
              surprises or impact on your cash flow. In a nutshell, we’re unlike
              any traditional accounting firm.
            </p>
          </SideBySideText>
        </SideBySide>
      </Section>
    </SiteLayout>
  )
}

export default AboutUs
